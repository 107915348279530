<template>
  <find-patient-modal :open="findPatientModalOpen" @close="onCloseFindPatientModal">
  </find-patient-modal>
  <nav>
    <div class="nav-left">
      <div class="datma-logo"><img :src="require('./img/datmaLogo.png')" @click="showapp = !showapp" /></div>
    </div>
    <div class="nav-center">
      <div class="datma-360-logo">
        <div class="d360-logo-container">
          <div class="d360-logo">
            <img class="d360" :src="require('./img/datma-360-white.png')" />
          </div>
          <div class="d360-text">
            dātma.360
          </div>
        </div>
      </div>
    </div>
    <div class="nav-right">
      <div class="nav-search">
        <div class="nav-search-icon icon-button-close" @keyup.enter="onOpenFindPatientModal"
          @click.prevent="onOpenFindPatientModal" role="button">
          <span class="material-symbols-outlined size-24">
            search
          </span>
        </div>
      </div>
      <div id="profile-icon" class="profile-icon" @click="showAccountMenu = !showAccountMenu"><span
          class="material-symbols-outlined icon-button-close">
          account_circle
        </span></div>
    </div>
    <div v-if="showAccountMenu" class="card flex justify-content-center account-menu">
      <Menu id="account-menu">
        <template #start>
          <button @click.prevent="setShowUserProfile()"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200">
            <span class="material-symbols-outlined icon-button-to-primary">account_circle</span>
            <span class="ml-3">User profile</span>
          </button>
          <button @click.prevent="onSignOut"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200">
            <span class="material-symbols-outlined icon-button-to-primary">logout</span>
            <span class="ml-3">Sign out</span>
          </button>
        </template>
      </Menu>
    </div>
  </nav>
  <transition>
    <div v-if="showapp" class="app-selector-container">
      <div class="app-selector-row">
        <div class="app-icon-container">
          <div class="app-icon-color-background">
          </div>
          <img class="dfed" :src="require('./img/datma-FED-white.png')" />
        </div>
        <div class="app-name-container">dātma.FED</div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import FindPatientModal from './FindPatientModal/FindPatientModal.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import Menu from 'primevue/menu'
import { onSignOut } from '@/common/shared.js'

const store = useStore()
const showapp = ref(false)
const showAccountMenu = ref(false)
const showUserProfile = ref(false)

document.addEventListener('click', function (event) {
  const profileIcon = document.getElementById('profile-icon')
  if (!profileIcon.contains(event.target)) {
    showAccountMenu.value = false
  }
})

const isSessionReadOnly = computed(() => store.getters.isSessionReadOnly)
const isChartLaunchedPatient = computed(() => {
  const patient = store.getters.session.patient
  return !!(patient?.isChartLaunchedPatient)
})

const setShowUserProfile = () => {
  showAccountMenu.value = !showAccountMenu.value
  showUserProfile.value = !showUserProfile.value
  store.dispatch('setShowUserProfile', { showUserProfile, publish: true })
}

const canClearOrSelectPatient = () => !(isChartLaunchedPatient.value) && !(isSessionReadOnly.value)

const findPatientModalOpen = computed(() => store.getters.isFindPatientModalOpen || false)

const onOpenFindPatientModal = () => {
  if (!canClearOrSelectPatient()) {
    // return
    console.log('can clear or select patient: ', canClearOrSelectPatient())
  }
  store.commit('setFindPatientModalOpen', true)
}
const onCloseFindPatientModal = () => store.commit('setFindPatientModalOpen', false)
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity ease 200ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.app-selector-container {
  position: absolute;
  width: 180px;
  height: 70px;
  top: 70px;
  left: 0;
  background-color: var(--Grey900);
  color: var(--Grey500);
  z-index: 10;
  transition: all linear 200ms;
  overflow: hidden;
}

.app-selector-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

  &:hover .app-icon-color-background {
    cursor: pointer;
    opacity: 1;
    transition: all ease-out 200ms;
  }

  &:hover .app-name-container {
    color: var(--White);
    transition: all ease-out 200ms;
  }
}

.app-name-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  font-weight: 600;
  font-size: .9rem;
  transition: all ease-out 200ms;
}

.app-icon-container {
  margin: 5px 5px;
  height: 56px;
  width: 56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfed {
  position: absolute;
  top: 11px;
  left: 12px;
}

.app-icon-color-background {
  background-color: var(--datmaFed);
  width: 56px;
  height: 56px;
  opacity: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all ease-out 200ms;
}


nav {
  background-color: var(--Black);
  color: var(--Grey500);
  height: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-left {
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: left;
  width: 33%;
}

.nav-right {
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: right;
  width: 33%;
}

.nav-center {
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: center;
  width: 33%;
}

.datma-logo {
  cursor: pointer;
}

#nav-next-patient,
.nav-next-patient-block {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

#nav-next-patient {
  padding-left: 10px;
  font-size: 0.9rem;
}

.d360-text {
  font-size: 0.8rem;
  font-weight: 600;
  color: #ccc;
}

.datma-360-logo {
  position: absolute;
  top: 10px;
  margin: auto;
}

.profile-icon {
  margin: 0 20px;
}

.profile-icon {
  &:hover {
    cursor: pointer;

    span {
      color: #fff;
    }
  }
}

.nav-search-icon {
  height: 30px;
  width: 30px;
  margin-top: 2px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  &:hover {
    cursor: pointer;

    span {
      color: #fff;

    }
  }
}

.nav-search input {
  background-color: #212121;
  border: 0;
  padding: 5px;
  color: #eee;
  outline: none;
  font-size: 1.0rem;
  height: 30px;
}

.account-menu {
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 100;
  padding: 8px 0px;
  border-radius: 12px;
  background: var(--Grey100);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.p-link {
  background-color: var(--Grey100);
}
</style>

<style>
.p-menu {
  padding: 0;
  border: none;
}
</style>
